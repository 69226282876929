import React, { useState } from 'react'
import { useAuth } from '../../../../contexts/authContext'
import { Button, Form, Spinner } from 'react-bootstrap'
import { PlusLg } from 'react-bootstrap-icons'
import { useTheme } from '../../../../contexts/themeContext'
import { Timestamp } from 'firebase/firestore'

const AddSocialCodeForm = () => {
    const { currentUser, userData, updateUserData, getSocialData, setSocialData, addSocialData, earnMedal, getCreatorAveragePPI, setSuccess, setError, setWarning } = useAuth()
    const { getThemeBGPlatform, getText, getTextInv, darkMode } = useTheme()

    const [socialCode, setSocialCode] = useState('')
    const [loading, setLoading] = useState(false)

    const verifySocialCode = async () => {
        if ( socialCode === userData.socialCode ) {
            setWarning('You can\'t connect with yourself!')
        } else if ( socialCode.length < 4 || socialCode > 20 ) {
            setWarning("Social names must be between 4 and 20 characters")
        } else if ( !/^[a-zA-Z0-9]+$/.test(socialCode) ) {
            setWarning('Invalid Social name (must be alphanumeric)')
        } else if ( userData.social && userData.social.connections.includes(socialCode) ) {
            setWarning('Already connected to ' + socialCode)
        } else if ( !userData.socialCode ) {
            try {
                const docSnap = await getSocialData(socialCode)
                if (docSnap.exists()) setWarning('Social name ' + socialCode + ' is already taken. Try a different one!')
                else return true
            } catch (err) {
                setWarning('Could not connect to the SociAble Network')
            }
        } else {
            return true
        }
        return false
    }

    const addSocialCode = async (e) => {
        e.preventDefault()
        setLoading(true)

        const isGoodCode = await verifySocialCode()
        if (!isGoodCode) {
            setSocialCode('')
            setLoading(false)
            return
        }

        if (userData.social) await addFriendCode().then(() => setSocialCode('') || setLoading(false))
        else await addNewCode().then(() => setSocialCode('') || setLoading(false))
    }

    const addFriendCode = async () => {
        let recentConnCount = 0
        const oneHourAgo = new Date()
        oneHourAgo.setTime(oneHourAgo.getTime() - 60*60*1000)
        if (userData.social.numRecentConns && userData.social.lastConnTime) {
            if ( userData.social.numRecentConns >= 10 && userData.social.lastConnTime.toDate() > oneHourAgo ) {
                setWarning('Maximum of 10 new connections per hour')
            } else if (userData.social.lastConnTime.toDate() > oneHourAgo) {
                recentConnCount = userData.social.numRecentConns + 1
            }
        }

        try {
            const socialDoc = await getSocialData(socialCode)
            if ( !socialDoc.exists() ) {
                setWarning('Social name not associated with any Creators')
                return
            }

            const socialData = socialDoc.data()
            if (!socialData.connections.includes(userData.socialCode)) await setSocialData({connections: [...socialData.connections, userData.socialCode], tokens: socialData.tokens + 1, score: socialData.score + 1}, socialCode)
            if (!userData.social.connections.includes(socialCode)) await setSocialData({connections: [...userData.social.connections, socialCode], tokens: userData.social.tokens + 1, score: userData.social.score + 1, numRecentConns: recentConnCount, lastConnTime: Timestamp.now()})
            setSuccess('Connected with ' + (socialData.nickname ? socialData.nickname : (socialData.userName ? socialData.userName : socialCode)) + '! You both just earned a token!')
        } catch(err) {
            setError('Failed to connect to this Social name, please confirm it is correct')
        }
    }

    const addNewCode = async () => {
        const ppi = getCreatorAveragePPI()
        let newSocialData = {
            connections: [],
            medals: {},
            channels: [...userData.channels.map(channel => channel.platform + '-' + channel.handle)],
            score: 0,
            tokens: 0,
            tier: 1,
            creationDate: Timestamp.fromDate(new Date(currentUser.metadata.creationTime)),
            buckets: userData.buckets || [],
            subBuckets: userData.subBuckets || [],
            ppi: ppi,
        }
        if (currentUser.username) newSocialData.username = currentUser.username
        if (currentUser.email) newSocialData.email = currentUser.email
        if (userData.name) newSocialData.name = userData.name
        if (userData.nickname) newSocialData.nickname = userData.nickname
        if (currentUser.photoURL) newSocialData.photoURL = currentUser.photoURL

        try {
            await updateUserData({socialCode: socialCode})
            await addSocialData(newSocialData, socialCode)
            setSuccess('Added to the SociAble Network!')
            if (userData.applicationData && Object.keys(userData.applicationData).length) earnMedal(3)
            if (userData.channels.length) earnMedal(4, socialCode, {variant: Math.min(userData.channels.length, 3), value: userData.channels.length})
        } catch (err) {
            setError('Failed to connect to SociAble Network, try again later')
        }
    }

    return (
        <Form className='d-flex flex-column w-100 pb-3' id='socialCodeForm' onSubmit={addSocialCode}>
            <Form.Label className='text-start fw-bold text-nowrap ps-3 pt-2 mb-0'>{userData.social ? 'Enter a Social Name: ' : 'Enter a New Social Name (Can\'t be Changed!): '}</Form.Label>
            <div className='d-inline-flex'>
                <Form.Control 
                    type='text' 
                    required 
                    value={socialCode} 
                    onChange={(e) => setSocialCode(e.target.value)} 
                    placeholder={userData.social ? 'Connect with a Friend! (case sensitive)' : 'Must be alphanumeric and between 4 and 20 characters'}
                    className={'border-0 bg-transition ' + getThemeBGPlatform('secondary') + (darkMode ? ' ' : '-light ') + getTextInv()} 
                />
                <Button type='submit' className='border-0 bg-transparent m-0 p-0'>
                    <div className={'bs-icon bs-icon-lg text-transition ' + getText()}>
                        {loading ? <Spinner /> : <PlusLg />}
                    </div>
                </Button>
            </div>
        </Form>
    )
}

export default AddSocialCodeForm